<template>
  <TagbyOverlay
    :show="state.banner.isUploading"
  >
    <BCard>
      <BRow>
        <BCol
          cols="12"
          md="6"
        >
          <BButtonGroup class="ml-1">
            <BButton
              variant="outline-primary"
              :disabled="hasNewBanner"
              @click="createNewBanner"
            >
              새로 만들기
            </BButton>
            <BButton
              v-if="banner != null"
              variant="outline-primary"
              @click="turnOnDeleteModal(banner.uuid)"
            >
              삭제
            </BButton>
            <BButton
              v-if="banner != null"
              variant="outline-primary"
              :disabled="isFirstOrder"
              @click="movePrevBanner"
            >
              순서 앞으로
            </BButton>
            <BButton
              v-if="banner != null"
              variant="outline-primary"
              :disabled="isLastOrder"
              @click="moveNextBanner"
            >
              순서 뒤로
            </BButton>
          </BButtonGroup>

          <BCarousel
            v-if="banner != null"
            v-model="state.banner.order"
            :interval="null"
            controls
            indicators
          >
            <BCarouselSlide
              v-for="banner in state.content"
              :key="banner.idx"
              class="card-img-overlay"
              caption-tag="h2"
              :caption-html="parseCarouselCaption(banner.header_1)"
              :text-html="parseCarouselText(banner.header_2)"
              :img-src="banner.img_url"
            />
          </BCarousel>
        </BCol>
        <BCol
          v-if="banner != null"
          cols="12"
          md="6"
        >
          <BFormGroup
            label="IMG"
          >
            <BFormFile @input="inputImgFile" />
          </BFormGroup>

          <BFormGroup
            label="REDIRECT_PATH"
          >
            <TagbySelect
              v-model="banner.redirect_option"
              :reduce="opt => opt.value"
              :options="redirectOptions"
              @input="inputRedirectPath"
            />
          </BFormGroup>

          <BFormGroup
            v-if="banner.redirect_option === '/campaign'"
            label="CAMPAIGN_IDX"
          >
            <BFormInput
              v-model="banner.option_text"
              type="number"
            />
          </BFormGroup>

          <BFormGroup
            v-if="banner.redirect_option === 'link'"
            label="LINK"
          >
            <BFormInput
              v-model="banner.option_text"
            />
          </BFormGroup>

          <BFormGroup
            label="TITLE"
          >
            <BFormTextarea v-model="banner.header_1" />
          </BFormGroup>

          <BFormGroup
            label="TEXT"
          >
            <BFormTextarea v-model="banner.header_2" />
          </BFormGroup>

          <BFormGroup
            label="STATE"
          >
            <TagbySelect
              v-model="banner.state"
              :options="stateOptions"
            />
          </BFormGroup>

          <BFormGroup
            label="START_AT"
          >
            <TagbyDatetimeInputV3 v-model="banner.start_at" />
          </BFormGroup>

          <BFormGroup
            label="END_AT"
          >
            <TagbyDatetimeInputV3 v-model="banner.end_at" />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </TagbyOverlay>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BCarousel,
  BCarouselSlide,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButtonGroup,
  BButton,
} from 'bootstrap-vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyOverlay from '@/components/TagbyOverlay.vue'
import state from '../../state'
import useBanner from '../../useBanner'
import useDeleteModal from '../../useDeleteModal'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BCarousel,
    BCarouselSlide,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    TagbyDatetimeInputV3,
    TagbySelect,
    TagbyOverlay,
    BButtonGroup,
    BButton,
  },
  setup() {
    const {
      banner,
      inputImgFile,
      hasNewBanner,
      createNewBanner,
      moveNextBanner,
      movePrevBanner,
      isLastOrder,
      isFirstOrder,
      inputRedirectPath,
      deleteBanner,
    } = useBanner()

    const {
      turnOnModal: turnOnDeleteModal,
    } = useDeleteModal()

    const stateOptions = [
      'ACTIVE',
      'INACTIVE',
    ]

    const redirectOptions = [
      { label: '홈', value: '/main/home' },
      { label: '캠페인 상세', value: '/campaign' },
      { label: '추천 캠페인', value: '/campaigns/recommend' },
      { label: '최근 등록 상품', value: '/products/recent' },
      { label: '링크', value: 'link' },
    ]

    const parseTextToHtml = text => text?.replaceAll('\n', '<br>') ?? ''
    const parseCarouselCaption = text => `<div class='text-white text-left'>${parseTextToHtml(text)}</div>`
    const parseCarouselText = text => `<div class='text-left'>${parseTextToHtml(text)}</div>`

    return {
      state,
      stateOptions,
      redirectOptions,
      parseCarouselCaption,
      parseCarouselText,
      banner,
      inputImgFile,
      hasNewBanner,
      createNewBanner,
      moveNextBanner,
      movePrevBanner,
      isLastOrder,
      isFirstOrder,
      inputRedirectPath,
      turnOnDeleteModal,
    }
  },
})
</script>
